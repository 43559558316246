<template>
  <div class="web-settings" v-loading="loading">
    <el-form
      size="medium"
      label-position="right"
      label-width="85px"
      class="small-form"
    >
      <div class="config-section-title">常规设置</div>
      <el-form-item label="后台图标：">
        <single-media-wall
          v-if="config.admin_nav_logo"
          v-model="config.admin_nav_logo"
          :showMask="showMaskOne"
          ratio="180:60"
          :width="180"
          :height="60"
          :border-radius="4"
          add-text=""
        >
          <p slot="info" class="info">
            <i class="el-icon-warning" />建议尺寸 180*60
          </p>
        </single-media-wall>
      </el-form-item>
      <el-form-item label="登录页标题：">
        <el-input
          v-model="config.login_title"
          type="text"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录页描述：">
        <el-input
          v-model="config.login_desc"
          type="text"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录页图标：">
        <single-media-wall
          :showMask="showMaskFour"
          v-model="config.login_icon"
          width="340px"
          height="60px"
          fit="contain"
          border-radius="4px"
          ratio="340:60"
          add-text=""
        >
          <p slot="info" class="info">
            <i class="el-icon-warning" />建议尺寸 340*60
          </p>
        </single-media-wall>
      </el-form-item>
      <el-form-item label="登录页背景：">
        <single-media-wall
          :showMask="showMaskThree"
          v-model="config.login_background"
          width="340px"
          height="191px"
          ratio="1920:1080"
          border-radius="4px"
          add-text=""
        >
          <p slot="info" class="info">
            <i class="el-icon-warning" />建议尺寸 1920*1080
          </p>
        </single-media-wall>
      </el-form-item>
      <el-form-item label="标签页标题：">
        <el-input
          v-model="config.web_tab_title"
          type="text"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签页图标：">
        <single-media-wall
          :showMask="showMaskTwo"
          v-model="config.web_tab_logo"
          width="60px"
          height="60px"
          border-radius="4px"
          ratio="16:16"
          add-text=""
        >
          <p slot="info" class="info">
            <i class="el-icon-warning" />建议尺寸 16*16，格式为 .ico
          </p>
        </single-media-wall>
      </el-form-item>
      <div class="config-section-title">快捷跳转</div>
    </el-form>
    <el-button type="primary" size="medium" @click="addFastJump"
      >添加</el-button
    >
    <el-table
      class="thead-light"
      stripe
      :data="config.admin_fast_jumps"
      id="sortTable"
      row-key="flag"
    >
      <el-table-column type="index" label="序号" width="70"></el-table-column>
      <el-table-column label="排序" width="60">
        <i class="el-icon-sort cursor-pointer"></i>
      </el-table-column>
      <el-table-column label="图标" width="130">
        <template slot="header" slot-scope="scope">
          <label
            >图标
            <el-tooltip content="建议尺寸 24*24" placement="top">
              <i class="el-icon-info" /> </el-tooltip
          ></label>
        </template>
        <template slot-scope="scope">
          <single-media-wall
            ratio="24:24"
            v-model="scope.row.logo_normal"
            :width="50"
            :height="50"
            :border-radius="4"
            add-text=""
            directly
            :show-mask="false"
            @handleMediaData="copyIcon(scope.$index, scope.row)"
          >
          </single-media-wall>
        </template>
      </el-table-column>
      <el-table-column label="选中图标" width="100">
        <template slot-scope="scope">
          <single-media-wall
            v-model="scope.row.logo"
            :width="50"
            :height="50"
            :border-radius="4"
            add-text=""
            :show-preview="false"
            directly
          >
          </single-media-wall>
        </template>
      </el-table-column>
      <el-table-column label="名称">
        <template slot-scope="scope">
          <PopoverInputEdit v-model="scope.row.name" />
        </template>
      </el-table-column>
      <el-table-column label="跳转链接">
        <template slot-scope="scope">
          <PopoverInputEdit v-model="scope.row.url" />
        </template>
      </el-table-column>
      <el-table-column label="显示" width="150">
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.enable"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-button style="color: #3576FF" size="small" type="text" @click="handleShowAdd(scope.row,scope.$index)">编辑</el-button>-->
          <el-button type="text" @click="removeFastJump(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <fixed-action-bar v-if="!loading">
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import { getAdminSystem, setAdminSystem } from '../api/web-settings'
import { randomString } from '@/base/utils/tool'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import Sortable from 'sortablejs'
import SingleMediaWall from '../../common/components/SingleMediaWall'
import FixedActionBar from '../../../base/layout/FixedActionBar'

export default {
  components: {
    FixedActionBar,
    SingleMediaWall,
    PopoverInputEdit,
  },
  data() {
    return {
      loading: true,
      config: {
        web_tab_logo: '',
        admin_nav_logo:
          window.serverConfig.VUE_APP_ADMINURL + '/admin/images/login_logo.png',
        login_icon: '',
        login_background: '',
      },
      showImageList: false,
      imageType: 0,
      fastIndex: 0,
      showMaskOne: true,
      showMaskTwo: true,
      showMaskThree: true,
      showMaskFour: true,
    }
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        if (!val.admin_nav_logo) {
          val.admin_nav_logo =
            window.serverConfig.VUE_APP_ADMINURL +
            '/admin/images/login_logo.png'
        }
        this.showMaskOne =
          val.admin_nav_logo !==
          window.serverConfig.VUE_APP_ADMINURL + '/admin/images/login_logo.png'

        if (!val.web_tab_logo) {
          val.web_tab_logo =
            window.serverConfig.VUE_APP_ADMINURL + '/favicon.ico'
        }
        this.showMaskTwo =
          val.web_tab_logo !==
          window.serverConfig.VUE_APP_ADMINURL + '/favicon.ico'

        if (!val.login_background) {
          val.login_background =
            window.serverConfig.VUE_APP_ADMINURL +
            '/admin/images/login_background.jpg'
        }
        this.showMaskThree =
          val.login_background !==
          window.serverConfig.VUE_APP_ADMINURL +
            '/admin/images/login_background.jpg'

        if (!val.login_icon) {
          val.login_icon =
            window.serverConfig.VUE_APP_ADMINURL +
            '/admin/images/login_logo.png'
        }
        this.showMaskFour =
          val.login_icon !==
          window.serverConfig.VUE_APP_ADMINURL + '/admin/images/login_logo.png'
      },
    },
  },
  created() {
    getAdminSystem()
      .then((res) => {
        this.config = res.data
        this.config.admin_fast_jumps = res.data.admin_fast_jumps.map(
          (item, index) => {
            return Object.assign({}, item, {
              flag: randomString(),
            })
          }
        )
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
      })
  },
  mounted() {
    this.drag()
  },
  methods: {
    copyIcon(i, row) {
      if (row.logo_normal && !row.logo) {
        this.config.admin_fast_jumps[i].logo = row.logo_normal
      }
    },
    // 拖动
    drag() {
      // 首先获取需要拖拽的dom节点
      const el1 = document
        .querySelectorAll('#sortTable .el-table__body-wrapper')[0]
        .querySelectorAll('table > tbody')[0]
      Sortable.create(el1, {
        handle: '.el-icon-sort',
        disabled: false, // 是否开启拖拽
        ghostClass: 'sortable-ghost', //拖拽样式
        animation: 150, // 拖拽延时，效果更好看
        group: {
          // 是否开启跨表拖拽
          pull: false,
          put: false,
        },
        onEnd: (e) => {
          // 这里主要进行数据的处理，拖拽实际并不会改变绑定数据的顺序，这里需要自己做数据的顺序更改
          let arr = this.config.admin_fast_jumps // 获取表数据
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]) // 数据处理
          this.$nextTick(function () {
            this.config.admin_fast_jumps = arr
          })
        },
      })
    },
    removeFastJump(e) {
      this.config.admin_fast_jumps.splice(e, 1)
    },
    addFastJump() {
      this.config.admin_fast_jumps.push({
        name: '',
        enable: 1,
        url: '',
        logo: '',
        logo_normal: '',
        flag: randomString(),
      })
    },
    saveConfig() {
      this.loading = true
      let config = JSON.parse(JSON.stringify(this.config))
      config.admin_fast_jumps = config.admin_fast_jumps.map((item) => {
        delete item.flag
        return item
      })
      setAdminSystem({
        config: config,
      })
        .then((res) => {
          this.$message.success(res.msg)
          this.$store.commit('header/setIsRefresh', true)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.web-settings {
  #sortTable {
    margin-top: 20px;
  }
}
</style>
