import api from "../../../base/utils/request";

export const getAdminSystem = data => {
  return api({
    url: '/admin/admin/Settings/getAdminSystemConfig',
    method: 'post',
    data
  })
}

export const setAdminSystem = data => {
  return api({
    url: '/admin/admin/Settings/setAdminSystemConfig',
    method: 'post',
    data
  })
}
